<template>
  <b-table
    v-if="fields"
    id="skill-search-table"
    striped
    no-border-collapse
    :busy="isSearching"
    :fields="fields"
    :items="mergedSkills"
  >
    <template v-slot:table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle mr-2" />
        <strong class="loading-text">Loading...</strong>
      </div>
    </template>

    <template v-slot:cell(lastName)="data">
      <router-link
        :to="{ name: 'Applicant', params: { userId: data.item.id }}"
        exact
      >
        {{ data.value }}
      </router-link>
    </template>
  </b-table>

  <div v-else>
    No results
  </div>
</template>

<script>
import sSkillLevels from "@/assets/SkillLevels"
import excitementLevelManager from "@/utils/excitementLevels"
import { useStore } from "../store.js"
import { mapState, mapGetters } from 'pinia'

export default {
  name: "SkillSearchTable",

  props: {
    userSkills: {
      type: Array,
      default: () => []
    },
    isSearching: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(useStore, ['searchType']),
    ...mapGetters(useStore, ['skillsSearchProperties']),

    fields() {
      return [
        {
          key: "lastName",
          label: "Applicant Name",
          formatter: this.FullNameFormatter,
          sortable: true,
          class: "skillSearchCell"
        },
        {
          key: "excitementLevel",
          label: "Excitement",
          formatter: this.ExcitementFormatter,
          class: "skillSearchCell",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "skillSearchCell"
        },
        {
          key: "atsUrl",
          label: "Hire Profile",
          class: "skillSearchCell",
          sortable: true
        }
      ].concat(this.uniqueSkills.map((skill) => ({
        key: skill.name,
        label: skill.name,
        formatter: this.SkillFormatter,
        class: "skillSearchCell",
        sortable: true,
      })))
        // TODO
        //          .concat(
        //           {
        //              key: 'textMatches',
        //              label: 'Text Matches'
        //           })

    },

    uniqueSkills() {
      return [...this.effectiveUserSkills.reduce((skills, user) => {
        (user.skills || []).forEach(skill => {
          skills.add(skill)
        })
        return skills
      }, new Set)]
    },

    skillTemplate() {
      return this.uniqueSkills.reduce((template, skill) => {
        if (skill.name) {
          template[skill.name] = 0
        }
        return template
      }, {})
    },

    effectiveUserSkills() {
      return this.userSkills.length > 0 ? this.userSkills : this.skillsSearchProperties.results || []
    },

    /**
     * Transforms the search results into a form we can use with the table.
     */
    mergedSkills() {
      let idToSkills = new Map

      this.effectiveUserSkills.forEach((user) => {
        if (!idToSkills.has(user.id)) {
          idToSkills.set(user.id, {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            excitementLevel: user.excitementLevel,
            status: user.status,
            ...this.skillTemplate
          })
        }

        // Update the skill map to the user's setting
        let userRow = idToSkills.get(user.id)
        user.skills.forEach((skill) => {
          userRow[skill.name] = skill.level
        })
      })

      return [...idToSkills.values()]
    }
  },

  methods: {
    FullNameFormatter(value, key, item) {
      return item.firstName + " " + item.lastName
    },

    SkillFormatter(value) {
      if (value) {
        return sSkillLevels[value].name
      }
      return "undefined"
    },

    ExcitementFormatter(value) {
      const level = excitementLevelManager.normalizedExcitementLevel(value)
      return excitementLevelManager.labelForExcitementLevel(level)
    }
  }
}
</script>

<style scoped>

.category {
  width: 80px;
}

.skill {
  min-width: 80px;
}

.level {
  width: 120px;
}

.notes {
  min-width: 200px;
}

.categoryCell {
  font-weight: bold;
}

.skillSearchCell {
  text-align: start;
}

table, th, td {
  border: 1px solid black;
}

.b-table-sticky-header {
  max-height: none !important;
}

</style>
